import Vue from 'vue';
import AppealModal from './components/appeal_modal.vue';

let appealModalElement;
let appealModalVueInstance;
export const initAppealModal = ({ projectFullPath, path, id }) => {
  if (!(appealModalElement && appealModalVueInstance)) {
    appealModalElement = document.createElement('div');
    document.body.append(appealModalElement);

    appealModalVueInstance = new Vue(AppealModal);
    appealModalVueInstance.$mount(appealModalElement);
  }
  appealModalVueInstance.projectFullPath = projectFullPath;
  appealModalVueInstance.path = path;
  appealModalVueInstance.id = id;
  appealModalVueInstance.$refs.modal.show();
  return appealModalVueInstance;
};
