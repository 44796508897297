import { initAppealModal } from 'jh/appeal';

function deferredInitialization() {
  const $html = document.querySelector('html');
  setTimeout(() => $html.classList.add('jh-page-wrapper'), 1000);
}

requestIdleCallback(deferredInitialization);

document.addEventListener('click', (e) => {
  const el = e.target;
  if (el.classList.contains('js-appeal-modal')) {
    const { projectFullPath, path, id } = el.dataset;

    initAppealModal({
      projectFullPath,
      path,
      id,
    });
  }
});
